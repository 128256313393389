import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Check out the `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2019/04/08/superbot-2019-presentations/"
      }}>{`SuperBot Videos`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      